/* eslint-disable sort-keys, max-len, @typescript-eslint/no-magic-numbers */

const QSTAR_URL = 'https://qstar.se'

const bransle = `${QSTAR_URL}/bransle`
const hitta = `${QSTAR_URL}/hitta`
const information = `${QSTAR_URL}/information`
const kund = `${QSTAR_URL}/kund`
const omoss = `${QSTAR_URL}/omoss`
const tankkort = `${QSTAR_URL}/tankkort`
const gamlaKampanjer = `${information}/gamla-kampanjer`

const Urls = {
  fakturorKontobesked: 'fakturor-kontobesked',
  bransle: {
    _95: `${bransle}#95`,
    _98: `${bransle}#98`,
    _adBlue: `${bransle}#adblue`,
    _alkylatbensin: `${bransle}#alkylatbensin`,
    _dieselB0: `${bransle}#diesel-b0`,
    _dieselB7: `${bransle}#diesel-b7`,
    _e85: `${bransle}#e85`,
    _hvo100: `${bransle}#hvo100`,
    _rme100: `${bransle}#rme100`,
    _spolarvatska: `${bransle}#spolarvatska`,
    index: bransle
  },
  direktleverans: `${QSTAR_URL}/direktleverans`,
  external: {
    ansokKortCompany:
      'https://app.verified.eu/lib/iframes/qstar-company/?&access_token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOnsidWlkIjoiL2VtYWlsL2Fuc29rYW5AcXN0YXIuc2UiLCJlbWFpbCI6ImFuc29rYW5AcXN0YXIuc2UiLCJuYW1lc3BhY2UiOiIvY29tcGFuaWVzLzU4OWRhNjA2N2M4YWY5MTMwMDIxOTU2ZiJ9LCJqdGkiOiJvcnNwOG0tdHpubndtIiwiaWF0IjoxNDk3ODc2OTM0LCJyb2xlcyI6WyIvcm9sZXMvdG1wbC9xc3RhcmNvbXBhbnkiXSwiZXhwIjoxODEzNDA5NzM0fQ.N6Rw_jzpz7NEn6FlxN9MTJvVquZ0xB7lKccBW2Bjqb82JjLn9VgrV_NRdyroJiEqxmBuZHpd2ae7exTgnmADaANIOmHtq35n0q2VlWef_FwpOXCjI2YBshVytelfrvnPt4kVENlnwlK5Rlf2_vcHQSZwgsxyzIWtSM6KQ9Uq77x4uLvou2xZRLZniHppsaTwqLZPWceC8gkd9coIWNW2Jt34InJbUWOOs7jmO1qwrEQ_TAfduQTkl7cRue8duhFajg9_kaJSfJaH4k0eW3JF6bJa6qH04KrSsaHcprYqtK9z7xdFZ5Q_NcV55BJ1DBidZqDZaXBmHCiEVWC81SAiOQ',
    ansokKortPerson:
      'https://app.verified.eu/lib/iframes/qstar-person/?&access_token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOnsidWlkIjoiL2VtYWlsL2Fuc29rYW5AcXN0YXIuc2UiLCJlbWFpbCI6ImFuc29rYW5AcXN0YXIuc2UiLCJuYW1lc3BhY2UiOiIvY29tcGFuaWVzLzU4OWRhNjA2N2M4YWY5MTMwMDIxOTU2ZiJ9LCJqdGkiOiJvcnNwOG0tdHpubndtIiwiaWF0IjoxNDk3ODc2OTM0LCJyb2xlcyI6WyIvcm9sZXMvdG1wbC9xc3RhcmNvbXBhbnkiXSwiZXhwIjoxODEzNDA5NzM0fQ.N6Rw_jzpz7NEn6FlxN9MTJvVquZ0xB7lKccBW2Bjqb82JjLn9VgrV_NRdyroJiEqxmBuZHpd2ae7exTgnmADaANIOmHtq35n0q2VlWef_FwpOXCjI2YBshVytelfrvnPt4kVENlnwlK5Rlf2_vcHQSZwgsxyzIWtSM6KQ9Uq77x4uLvou2xZRLZniHppsaTwqLZPWceC8gkd9coIWNW2Jt34InJbUWOOs7jmO1qwrEQ_TAfduQTkl7cRue8duhFajg9_kaJSfJaH4k0eW3JF6bJa6qH04KrSsaHcprYqtK9z7xdFZ5Q_NcV55BJ1DBidZqDZaXBmHCiEVWC81SAiOQ',
    ecEuropa1:
      'https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_sv',
    ecEuropa2:
      'https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en',
    ecEuropa3:
      'https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_sv',
    minasidor: 'https://minasidor.qstar.se/',
    mypages: 'https://mypages.qstar.se/'
  },
  files: {
    funktionskontrollGasaterforingPumpar: `${QSTAR_URL}/static/media/Funktionskontroll Gasåterföring Pumpar.wmv`,
    nodatgardsplanerInstruktionerQstar: `${QSTAR_URL}/static/media/Nodatgardsplaner_&_Instruktioner_Qstar_1905.pdf`,
    nodatgardsplanerInstruktionerSwea: `${QSTAR_URL}/static/media/Nodatgardsplaner_&_Instruktioner_Swea_1905.pdf`
  },
  hitta,
  hittaEdit: `${QSTAR_URL}${hitta}?edit`,
  information: {
    cookies: `${information}/cookies`,
    foretagskund: `${information}/foretagskund`,
    informationTillArbetssokandeOchReferenspersoner: `${information}/information-till-arbetssokande-och-referenspersoner`,
    informationTillKontaktpersonerHosForetagskunder: `${information}/information-till-kontaktpersoner-hos-foretagskunder`,
    informationTillKontaktpersonerHosLeverantorer: `${information}/information-till-kontaktpersoner-hos-leverantorer`,
    informationTillKunder: `${information}/information-till-kunder`,
    informationTillPersonerVidIncidentrapportering: `${information}/information-till-personer-vid-incidentrapportering`,
    informationTillPotentiellaForetagskunder: `${information}/information-till-potentiella-foretagskunder`,
    integritetspolicy: `${information}/integritetspolicy`,
    integritetspolicyLander: `${information}/integritetspolicy-lander`,
    kampanjJuni: `${QSTAR_URL}${gamlaKampanjer}/100-25-ore-rabattliter-till-alla-kortkunder-den-6e-juni`,
    nodatgardsplanerInstruktioner: `${information}/nodatgardsplaner-instruktioner`,
    personuppgiftsbitradesavtal: `${information}/personuppgiftsbitradesavtal`,
    miljoinformation: `${QSTAR_URL}/miljoinformation`
  },
  kontakt: `kontakt`,
  kund: {
    _foretagskort: `${kund}#foretagskort`,
    _smartkortForetag: `${kund}#smartkort-foretag`,
    _smartkortPrivat: `${kund}#smartkort-privat`,
    _tankkortPrivat: `${kund}#tankkort-privat`,
    index: kund
  },
  login: `${QSTAR_URL}/login`,
  mailto: 'mailto:info@qstar.se',
  mailtoForsaljning: 'mailto:forsaljning@qstar.se',
  notFound: `${QSTAR_URL}/404`,
  omoss: {
    _bulk: `${omoss}#bulk`,
    _historia: `${omoss}#historia`,
    index: omoss
  },
  start: QSTAR_URL,
  tackAnsokan: `${QSTAR_URL}/tack-ansokan`,
  tankkortQstar: {
    avtalsvillkor: `${tankkort}/avtalsvillkor`,
    foretagskort: tankkort + '/foretagskort',
    index: tankkort,
    privatkort: tankkort + '/privatkort',
    smartkort: `${tankkort}/smartkort`,
    tankkort: `${tankkort}/tankkort`
  },
  tel: 'tel:011280000',
  tankkort: 'tankkort',
  kort: 'kort',
  smartkort: 'smartkort',
  profil: 'profil',
  loggaut: 'loggaut',
  ersättningskort: 'ersattningskort',
  hanteraAnvandare: 'hantera-anvandare',
  email: 'info@qstar.se',
  telephoneNumber: '011-28 00 00',
  tankkortSubheader: 'fakturor-kontobesked/tankkort',
  smartkortSubheader: 'fakturor-kontobesked/smartkort',
  bankkortSubheader: 'fakturor-kontobesked/bankcard',
  installningarSubmenu: '',
  hanteraAnvandareSubMenu: 'profil/hantera-anvandare'
}

export { Urls }
